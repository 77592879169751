import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Breadcrumb, Input, Table, Tag, Typography , Col, Space } from "antd";
import moment from "moment";
import "./register.css";
import EditUser from "./editUser";
import DeleteUser from "./deleteUser.js";
import { listUsers } from "../../api/queries";

const { Search } = Input;
const colors = ['cyan', 'geekblue', 'red', 'blue', 'green', 'yellow', 'orange', 'purple', 'pink', 'brown', 'lime', 'navy', 'maroon', 'olive', 'teal', 'aqua', 'fuchsia']; // 1:1 color for each role, department, district
const { Title } = Typography;

let AWS = require("aws-sdk");
let cognitoISP = new AWS.CognitoIdentityServiceProvider({
  region: "ap-southeast-1",
  accessKeyId: process.env.REACT_APP_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY,
});

export default class ManageUser extends React.Component {
  constructor() {
    super();
    this.state = { 
      data: [], 
      allData: [],
      loading:false 
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.listUser();
    }
  }

  listUser = async () => {
    this.setState({ loading: true });
    API.graphql(
      graphqlOperation(listUsers, {
        filter: { role: { notContains: "admin" } },
        limit: 1000,
      })
    ).then((res) => {
      const users = res.data.listUsers.items;
      const uniqueRoles = [...new Set(users.flatMap(user => user.role.split('|')))];
      const roleFilters = uniqueRoles.map(role => ({ text: role, value: role }));

      this.setState({
        data: res.data.listUsers.items.sort((a, b) => a.name.localeCompare(b.name)),
        allData: res.data.listUsers.items.sort((a, b) => a.name.localeCompare(b.name)),
        roleFilters,
        loading: false,
      });
    });
  };

  onSearch = async (e) => {
        let arr = this.state.allData;
        let s = e.toLowerCase();

        let search = arr.filter((item, i) => {
          let ss = `${item.name} ${item.mname} ${item.lname} ${item.role} ${item.username}`;
          let f = ss.toLowerCase().search(s) > -1;

          if (f) {
            return item;
          } else {
            return null;
          }
      });
      
      if (search.length > 0) {
        this.setState({ data: search });
      } else {
        this.setState({ data: null });
      }

      try {

        if (e.trim() !== "") {
            let params = {
              UserPoolId: 'ap-southeast-1_8bRtLOuCc', 
              Filter: `username ^= "${e}"`,
            };
          
            const isExistInCognito = await cognitoISP.listUsers(params).promise();
            
            if(isExistInCognito.Users.length > 0 && search.length === 0) {

              const cognitoUserExist = isExistInCognito.Users[0].Attributes;

              let nameMap = {
                "name": "name",
                "middle_name": "mname",
                "family_name": "lname",
                "phone_number": "contact",
                "birthdate": "bdate",
                "email": "username",
                "gender": "gender",
                "custom:role": "role",
                "custom:department": "department",
                "custom:status": "status",
                "custom:date": "date",
              };

              let cognitoUser = cognitoUserExist.reduce((acc, item) => {
                let key = nameMap[item.Name];
                if (key) {
                  acc[key] = item.Name === 'custom:date' ? moment(item.Value).format("YYYY-MM-DD") : item.Value;
                }
                return acc;
              }, {});

              cognitoUser['district'] = "";
              cognitoUser['saveMe'] = true; 

              this.setState({ data: [cognitoUser] });

            };
          } 
      } catch (error) {
        console.error(error);
      }
  };
  

  render() {
    const columns = [
      {
        title: "NAME",
        dataIndex: "name",
        key: "name",
        width: '200px',
        render: (text, record) => (
         <div>
          {`${record.name?.charAt(0).toUpperCase() + record.name?.slice(1).toLowerCase() ?? ''} 
                ${record.mname?.charAt(0).toUpperCase() + record.mname?.slice(1).toLowerCase() ?? ''} 
                ${record.lname?.charAt(0).toUpperCase() + record.lname?.slice(1).toLowerCase() ?? ''}`}
          </div>
        ),
      },
      {
        title: "BIRTHDATE",
        dataIndex: "bdate",
        key: "bdate",
      },
      {
        title: "CONTACT",
        dataIndex: "contact",
        key: "contact",
      },
      {
        title: "USERNAME",
        dataIndex: "username",
        key: "username",
        width: '280px',
      },
      {
        title: "ROLE/POSITION",
        dataIndex: "role",
        key: "role",
        width: '200px',
        render: (text, record) => (
          <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            {record?.role?.split('|').map((role, index) => (
              <Col key={index} span={24}>
                <Tag color={colors[index % colors.length]} style={{ marginBottom: '5px', borderRadius:"20px" }}>
                  {role}
                </Tag>
              </Col>
            ))}
          </div>
        ),
        filters: this.state.roleFilters,
        onFilter: (value, record) => record.role?.includes(value),
        filterSearch: true,
      },
      {
        title: "DEPARTMENT",
        dataIndex: "department",
        key: "department",
        width: '200px',
        render: (text, record) => (
          <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            {record?.department?.split('|').map((dept, index) => (
              <Col key={index} span={24}>
                <Tag color={colors[index % colors.length]} style={{ marginBottom: '5px', borderRadius:"20px" }}>
                  {dept}
                </Tag>
              </Col>
            ))}
          </div>
        ),
      },
      {
        title: "DISTRICT",
        dataIndex: "district",
        key: "district",
        width: '180px',
        render: (text, record) => (
          <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            {record?.district?.split('|').map((dist, index) => (
              <Col key={index} span={24}>
                <Tag color={colors[index % colors.length]} style={{ marginBottom: '5px', borderRadius:"20px" }}>
                  {dist ? dist : "--"}
                </Tag>
              </Col>
            ))}
          </div>
        ),
      },
     {
        title: "ACTIONS",
        dataIndex: "action",
        key: "action",
        width: '150px',
        render: (text, record) => (
          <Space size="small" style={{ display: 'flex', justifyContent:"center" }}>
            <EditUser data={record} refresh={() => this.listUser()} />
            <DeleteUser data={record} refresh={() => this.listUser()} />
          </Space>
        ),
      }
    ];
    return (
      <>
        {/* <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>User</Breadcrumb.Item>
        </Breadcrumb> */}
        <div className="tblHead">
          <Title>Manage all user</Title>
          <Search
            placeholder="Search user"
            style={{ width: '100%', maxWidth: '250px' }}
            onSearch={this.onSearch}
            enterButton
            allowClear
            className="custom-search"
          />
        </div>
        <div className="tableContainer">
          <Table
            rowKey="id"
            loading={this.state.loading}
            bordered
            dataSource={this.state.data}
            columns={columns}
            className="custom-table"
            size="small" // Add this line
            scroll={{ x: 'max-content' }}
            
          />
        </div>
      </>
    );
  }
}